import type { BlockTypes } from '@etf1-interne/tf1info_types_news'
import { PictureElementList } from '../../../atoms/PictureElementList'
import { mixins, theme } from '../../../../styles'
import { DurationLabel } from '../../../atoms/DurationLabel'
import { CarouselItemTitle } from '../components/CarouselItemTitle'
import { CarouselItemVideoIndicator } from '../components/CarouselItemVideoIndicator'

export function CarouselVerticalItem({
  title,
  pictures,
  duration,
  icon,
  link,
  hasMultiLive,
  onClick,
}: BlockTypes['carousel-highlights']['data']['elementList'][number] & {
  onClick: () => void
}): JSX.Element {
  return (
    <>
      <li className="CarouselVerticalItem">
        <article className="CarouselVerticalItem__Item">
          <div className="CarouselVerticalItem__Content flex flex-column justify-end">
            <CarouselItemTitle
              title={title}
              link={link}
              icon={icon}
              hasMultiLive={hasMultiLive}
              onClick={onClick}
            />
            {duration ? (
              <DurationLabel className="CarouselVerticalItem__Duration" duration={duration} />
            ) : null}
          </div>
          <PictureElementList {...pictures} aria-hidden={true} />
          <CarouselItemVideoIndicator icon={icon} />
        </article>
      </li>

      <style jsx>{`
        .CarouselVerticalItem {
          position: relative;
          width: 234px;
          min-width: 234px;
          max-width: 234px;
          height: 416px;
          min-height: 416px;
          border-radius: 4px;
          overflow: hidden;
        }

        .CarouselVerticalItem__Item {
          height: 100%;
          width: 100%;
        }

        .CarouselVerticalItem:has(a:focus) {
          outline: 5px solid ${theme.cssVars.focusBlue};
        }

        .CarouselVerticalItem :global(.CarouselItemTitle__Link:before) {
          content: ' ';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
        }

        .CarouselVerticalItem :global(.CarouselVerticalItem__Link) {
          outline: none;
        }

        .CarouselVerticalItem :global(img) {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .CarouselVerticalItem__Content {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          padding: 20px;
          gap: 12px;
          background: linear-gradient(180deg, rgba(15, 19, 55, 0.4) 15%, rgba(15, 19, 55, 0) 55%),
            linear-gradient(180deg, rgba(15, 19, 55, 0.04) 10%, rgba(15, 19, 55, 0.4) 60%);
        }

        .CarouselVerticalItem__Content :global(.CarouselVerticalItem__Duration) {
          position: absolute;
          top: 20px;
          z-index: 1;
        }

        @media ${mixins.mediaQuery.desktop} {
          .CarouselVerticalItem {
            width: 373px;
            min-width: 373px;
            max-width: 373px;
            height: 662px;
            min-height: 662px;
          }
        }
      `}</style>
    </>
  )
}
