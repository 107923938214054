import type { BlockTypes } from '@etf1-interne/tf1info_types_news'
import type { ComponentType } from 'react'
import type { ICarouselHighlightsBorder } from './components/CarouselHighlightsBorder'

import { useContext, useId, useRef } from 'react'
import dynamic from 'next/dynamic'
import slugify from 'slugify'
import { Link } from '../../atoms/Link'
import { SponsorBanner } from '../../molecules/SponsorBanner'
import { TagCommanderContext } from '../../../contexts/tagcommander'
import { Button } from '../../atoms/Button'
import { SVGIcon } from '../../atoms/SVGIcon'
import { PictureElementList } from '../../atoms/PictureElementList'
import { CarouselCardItem } from './items/CarouselCardItem'
import { CarouselThumbnailItem } from './items/CarouselThumbnailItem'
import { CarouselVerticalItem } from './items/CarouselVerticalItem'
import { useCarouselNavigation } from './useCarouselNavigation'
import { getContrastColor } from '../../../helpers/colors'
import { theme, mixins } from '../../../styles'
import { getElementPosition } from '../../../helpers/getElementPosition'
import { CLICK_VIDEO } from '../../../constants/tms'
import { clearSlash } from '../../../helpers/path'

const CarouselHighlightsBorder: ComponentType<ICarouselHighlightsBorder> = dynamic(() =>
  import('./components/CarouselHighlightsBorder').then((module) => module.CarouselHighlightsBorder),
)

const TYPES = {
  card: 'carte',
  thumbnail: 'vignette',
  vertical: 'verticale',
}

export function CarouselHighlights({
  title,
  htmlTitle,
  link,
  button,
  elementList,
  sponsor,
  backgroundColor,
  borderColor,
  backgroundImage,
  type,
}: BlockTypes['carousel-highlights']['data']): JSX.Element {
  const titleId = useId()
  const { hit } = useContext(TagCommanderContext)
  const listContainer = useRef<HTMLUListElement>(null)

  const { goToNextSlide, goToPrevSlide, isFirstElementVisible, isLastItemVisible } =
    useCarouselNavigation({
      container: listContainer.current,
      scaleAnimationEnabled: type !== 'thunbnail',
    })

  // Extract the title and the strong part of the title
  // ex: "Les emissions <strong>de TF1</strong>" => lightTitle = "Les emissions", strongTitle = "de TF1", rest = ""
  // This will help positioning the arrow in the title
  const match =
    htmlTitle?.match(
      /(?<lightTitle>.*(?=<strong>))<strong>(?<strongTitle>.*(?=<\/strong>)*)<\/strong>(?<rest>.*)/,
    ) || ({ groups: {} } as { groups: { [key: string]: string } })
  const lightTitle = match?.groups?.lightTitle || ''
  const strongTitle = match?.groups?.strongTitle || ''
  const rest = match?.groups?.rest || ''

  function handleOnClick(location = null) {
    hit(
      {
        screen_clickableElementName: `bloc_carousel-highlights_${
          location ? `${location}_` : ''
        }${slugify(title, {
          lower: true,
        })}`,
      },
      { isClickEvent: true },
    )
  }

  function hitCarouselElement(
    { title: elementTitle, link } = { title: null, link: null },
    y: number,
  ) {
    const x = getElementPosition('.CarouselHighlights__Carousel', listContainer.current)

    const paths = link?.length ? clearSlash(link).split('/') : []
    const path = paths[paths.length - 1]

    //title of carousel
    const titleSlugify = title?.length
      ? slugify(title, {
          lower: true,
        })
      : 'none'

    //if no title of element, we use the link of element
    const elementTitleSlugify = elementTitle?.length
      ? slugify(elementTitle, {
          lower: true,
        })
      : path?.length
      ? path
      : 'none'

    hit(
      {
        id: CLICK_VIDEO,
        screen_clickableElementName: `bloc_carousel-highlights_${
          type ? `${TYPES[type]}` : 'none'
        }_${titleSlugify}_${elementTitleSlugify}`,
        screen_position: `${x},${y}`,
      },
      { isClickEvent: true },
    )
  }

  function hitCarouselButton() {
    hit(
      {
        screen_clickableElementName: `bloc_carousel-highlights_button-${slugify(button.text, {
          lower: true,
        })}`,
      },
      { isClickEvent: true },
    )
  }

  const textColor =
    backgroundColor && backgroundColor !== 'transparent'
      ? getContrastColor(backgroundColor)
      : undefined

  return (
    <>
      <div className="CarouselHighlights flex flex-column items-center justify-center">
        <CarouselHighlightsBorder borderColor={borderColor} position="top" />
        <Link url={link}>
          <div className="CarouselHighlights__Header flex items-center justify-center">
            <div
              className="CarouselHighlights__Header__Title flex items-center justify-center"
              onClick={() => handleOnClick('titre')}
            >
              {!rest && strongTitle ? (
                <h2 id={titleId}>
                  <span dangerouslySetInnerHTML={{ __html: lightTitle }} />
                  <strong>
                    {strongTitle}
                    {link ? (
                      <SVGIcon
                        name="arrow"
                        size="var(--title-arrow-size)"
                        onClick={() => handleOnClick('fleche')}
                        primaryColor={textColor || theme.cssVars.fontColor}
                      />
                    ) : null}
                  </strong>
                </h2>
              ) : (
                <h2 id={titleId}>
                  <span dangerouslySetInnerHTML={{ __html: htmlTitle }} />
                  {link ? (
                    <SVGIcon
                      name="arrow"
                      size="var(--title-arrow-size)"
                      onClick={() => handleOnClick('fleche')}
                      primaryColor={textColor || theme.cssVars.fontColor}
                    />
                  ) : null}
                </h2>
              )}
            </div>
          </div>
        </Link>
        {sponsor?.title && (
          <div
            className="CarouselHighlights__Content__SponsorBanner"
            onClick={() => handleOnClick('logo-sponso')}
          >
            <SponsorBanner
              title={sponsor.title}
              link={sponsor.link}
              pictures={sponsor.pictures}
              type={sponsor.type}
              backgroundColor="transparent"
            />
          </div>
        )}

        <div className="CarouselHighlights__Carousel_Wrapper">
          {!isFirstElementVisible ? (
            <button
              tabIndex={-1}
              className="CarouselHighlights__NavigationArrows-left"
              onClick={goToPrevSlide}
            >
              <SVGIcon name="chevron" primaryColor={theme.cssVars.fontColor} size="36" />
            </button>
          ) : null}
          <ul
            aria-labelledby={title ? titleId : undefined}
            ref={listContainer}
            className="CarouselHighlights__Carousel flex flex-wrap"
          >
            {elementList.map((element, index) => {
              switch (type) {
                case 'thumbnail':
                  return (
                    <CarouselThumbnailItem
                      key={index}
                      {...element}
                      onClick={() => hitCarouselElement(element, index + 1)}
                    />
                  )
                case 'vertical':
                  return (
                    <CarouselVerticalItem
                      key={index}
                      {...element}
                      onClick={() => hitCarouselElement(element, index + 1)}
                    />
                  )
                default:
                  return (
                    <CarouselCardItem
                      key={index}
                      {...element}
                      onClick={() => hitCarouselElement(element, index + 1)}
                    />
                  )
              }
            })}
          </ul>
          {!isLastItemVisible ? (
            <button
              tabIndex={-1}
              className="CarouselHighlights__NavigationArrows-right"
              onClick={goToNextSlide}
            >
              <SVGIcon name="chevron" primaryColor={theme.cssVars.fontColor} size="36" />
            </button>
          ) : null}
        </div>
        {button?.link ? (
          <div className="CarouselHighlights__Button">
            <Button
              backgroundColor={theme.colors.white}
              textColor={theme.colors.deepBlue}
              href={button.link}
              onClick={hitCarouselButton}
            >
              <p className="CarouselHighlights__Button__Text">{button.text}</p>
              <SVGIcon name="arrow" />
            </Button>
          </div>
        ) : null}
        {backgroundImage && (
          <PictureElementList
            className="CarouselHighlights__Carousel_Background"
            {...backgroundImage}
            withPreload
          />
        )}
        <CarouselHighlightsBorder borderColor={borderColor} position="bottom" />
      </div>

      <style jsx>{`
        .CarouselHighlights {
          background-color: ${backgroundColor ? backgroundColor : 'initial'};
        }
      `}</style>
      <style jsx>{`
        .CarouselHighlights :global(.CarouselHighlights__Carousel_Background::after) {
          background-image: ${backgroundImage && backgroundColor
            ? `linear-gradient(
            180deg,
            ${theme.cssVars.transparent} 0,
            ${theme.cssVars.transparent} 40%,
            ${backgroundColor} 60%,
            ${backgroundColor} 100%
          )`
            : `linear-gradient(
            180deg,
            ${theme.cssVars.transparent} 0,
            ${theme.cssVars.transparent} 40%,
            ${theme.cssVars.bodyBackground} 60%,
            ${theme.cssVars.bodyBackground} 100%
          )`};
        }
      `}</style>
      <style jsx>{`
        .CarouselHighlights__Header {
          color: ${textColor ? textColor : theme.cssVars.fontColor};
        }
      `}</style>

      <style jsx>{`
        .CarouselHighlights__Carousel {
          gap: ${!type || type === 'card' ? '10px' : '20px'};
        }
      `}</style>

      <style jsx>{`
        :global([data-module='carousel-highlights'].isFollow)
          .CarouselHighlights
          :global(hr:last-of-type) {
          display: none;
        }

        .CarouselHighlights {
          position: relative;
          width: 100%;
          margin-bottom: 50px;
          gap: 24px;
          z-index: 2;
        }

        .CarouselHighlights :global(hr:last-of-type) {
          margin-top: 16px;
        }

        .CarouselHighlights__Content__SponsorBanner {
          width: 100%;
          margin-bottom: ${theme.block.marginBottom}px;
        }

        .CarouselHighlights__Header {
          position: relative;
          width: 100%;
          line-height: 23px;
          z-index: 2;
        }

        .CarouselHighlights__Header__Title {
          display: flex;
          flex-direction: column;
          gap: 10px;
          width: 100%;
          font-family: ${theme.fonts.overpass};
          padding: 0 20px;
        }

        .CarouselHighlights__Header__Title h2 {
          --title-arrow-size: 30px;
          font-size: 30px;
          line-height: 116%;
          font-weight: 250;
          display: inline;
          margin: 0;
          text-transform: uppercase;
          text-align: center;
        }

        .CarouselHighlights__Header__Title :global(strong) {
          display: inline-block;
        }

        .CarouselHighlights__Header__Title :global(svg) {
          display: inline-block;
          margin-bottom: -5px;
        }

        .CarouselHighlights__Header__Title :global(p) {
          color: ${theme.cssVars.midBlue};
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
        }

        .CarouselHighlights__Carousel_Wrapper {
          width: 100%;
          position: relative;
          z-index: 2;
        }

        .CarouselHighlights :global(.CarouselHighlights__Carousel_Background) {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
        }

        .CarouselHighlights :global(.CarouselHighlights__Carousel_Background::after) {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }

        .CarouselHighlights :global(.CarouselHighlights__Carousel_Background img),
        .CarouselHighlights :global(.CarouselHighlights__Carousel_Background amp-img) {
          height: 100%;
          width: 100%;
          object-fit: cover;
          opacity: 0.8;
        }

        .CarouselHighlights__Carousel {
          overflow-x: scroll;
          overflow-y: hidden;
          list-style: none;
          width: 100%;
          max-width: 100vw;
          scroll-behavior: smooth;
          scroll-snap-type: x mandatory;
          -webkit-overflow-scrolling: touch;
          -ms-overflow-style: none;
          scrollbar-width: none;
          padding: 4px 30px 4px 0;
          margin: 0 30px 0 0;
        }

        .CarouselHighlights__Carousel::-webkit-scrollbar {
          display: none;
        }

        .CarouselHighlights__Carousel :global(li) {
          scroll-snap-align: start;
          scroll-margin-left: 20px;
          scroll-snap-stop: always;
          transition: transform 0.1s linear;
        }

        .CarouselHighlights__Carousel :global(li:first-of-type) {
          margin-left: 20px;
        }

        .CarouselHighlights__NavigationArrows-left,
        .CarouselHighlights__NavigationArrows-right {
          display: none;
        }

        .CarouselHighlights__Button {
          padding: 0 30px;
          width: 100%;
          z-index: 2;
        }

        .CarouselHighlights__Button__Text {
          margin: 2.5px 10px 0 0;
        }

        @media ${mixins.mediaQuery.tablet} {
          .CarouselHighlights {
            width: inherit;
            padding: 0 0 0 20px;
            margin-top: 30px;
            gap: 40px;
          }

          .CarouselHighlights__Header {
            padding-right: 20px;
          }

          .CarouselHighlights__Header__Title h2 {
            gap: 5px;
          }

          .CarouselHighlights__Header__Title :global(p) {
            font-size: 17px;
          }

          .CarouselHighlights__Content__SponsorBanner {
            padding-right: 20px;
          }

          .CarouselHighlights__Button {
            max-width: 400px;
            margin-top: 30px;
          }

          .CarouselHighlights__Carousel {
            padding: 4px 20px;
            gap: 20px;
          }

          .CarouselHighlights__Carousel :global(li) {
            margin: 0;
            scroll-margin-left: 0;
          }
          .CarouselHighlights__Carousel :global(li:first-of-type) {
            margin-left: 0;
          }

          .CarouselHighlights__NavigationArrows-left,
          .CarouselHighlights__NavigationArrows-right {
            display: inline-block;
            opacity: 0;
            position: absolute;
            z-index: 2;
            top: 0;
          }

          .CarouselHighlights:hover .CarouselHighlights__NavigationArrows-left,
          .CarouselHighlights:hover .CarouselHighlights__NavigationArrows-right {
            color: ${theme.cssVars.bodyBackground};
            position: absolute;
            top: 0;
            width: 125px;
            height: 100%;
            margin: 0;
            border: none;
            cursor: pointer;
            opacity: 1;
            transition: opacity 0.3s;
          }

          .CarouselHighlights:hover .CarouselHighlights__NavigationArrows-left {
            background: linear-gradient(
              90deg,
              ${theme.cssVars.bodyBackground} 0%,
              ${theme.cssVars.bodyBackground} 20%,
              transparent 100%
            );
            padding: 0px 38px 0px 51px;
          }

          .CarouselHighlights:hover .CarouselHighlights__NavigationArrows-right {
            background: linear-gradient(
              90deg,
              transparent 0%,
              ${theme.cssVars.bodyBackground} 80%,
              ${theme.cssVars.bodyBackground} 100%
            );
            padding: 0px 39px 0px 50px;
          }

          .CarouselHighlights .CarouselHighlights__NavigationArrows-left :global(svg) {
            transform: rotate(180deg);
          }

          .CarouselHighlights .CarouselHighlights__NavigationArrows-left {
            left: 0;
          }

          .CarouselHighlights .CarouselHighlights__NavigationArrows-right {
            right: 0;
          }
        }

        @media ${mixins.mediaQuery.desktop} {
          .CarouselHighlights {
            width: inherit;
            padding: 0 0 0 90px;
            margin-top: 30px;
          }

          .CarouselHighlights__Header {
            padding-right: 90px;
          }

          .CarouselHighlights__Header__Title h2 {
            --title-arrow-size: 36px;
            font-size: 36px;
          }

          .CarouselHighlights__Header__Title :global(svg) {
            margin-bottom: -5px;
          }

          .CarouselHighlights__Content__SponsorBanner {
            padding-right: 90px;
          }

          .CarouselHighlights__Content__SponsorBanner :global(.SponsorBanner) {
            border-radius: 5px;
          }

          .CarouselHighlights__Carousel :global(li) {
            margin-right: 0;
            margin-left: 0;
            scroll-margin-left: 0;
          }
        }
      `}</style>
    </>
  )
}
