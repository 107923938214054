import { theme } from '../../../../styles'
import { SVGIcon } from '../../../atoms/SVGIcon'

export function CarouselItemVideoIndicator({ icon }) {
  if (icon !== 'video') {
    return null
  }

  return (
    <>
      <SVGIcon
        name="play-circle"
        className="CarouselItemVideoIndicator"
        secondaryColor={theme.cssVars.white}
        size="34px"
      />
      <style global jsx>{`
        svg.CarouselItemVideoIndicator {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      `}</style>
    </>
  )
}
